import React, { useEffect, useRef } from "react";
import classes from './WhyChoose.module.scss';
import { Trans, useTranslation } from "react-i18next";
import icon_01 from '../../../assets/img/ConditionsPage/WhyChoose/icon_01.svg';
import icon_02 from '../../../assets/img/ConditionsPage/WhyChoose/icon_02.svg';
import icon_03 from '../../../assets/img/ConditionsPage/WhyChoose/icon_03.svg';
import icon_04 from '../../../assets/img/ConditionsPage/WhyChoose/icon_04.svg';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const WhyChoose = () => {
    const { t } = useTranslation();
    const cardsRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            icon: icon_01,
            text: 'conditions_74',
        },
        {
            ref: cardRef_02,
            icon: icon_02,
            text: 'conditions_75',
        },
        {
            ref: cardRef_03,
            icon: icon_03,
            text: 'conditions_76',
        },
        {
            ref: cardRef_04,
            icon: icon_04,
            text: 'conditions_77',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.timeline().fromTo(cardRef_01.current, {
                    x: -130,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardsRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_02.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: cardsRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_03.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: cardsRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(cardRef_04.current, {
                    x: 130,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: cardsRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className="mt container">
            <div className={classes.whyChoose}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t("conditions_78")}
                </h2>
                <div ref={cardsRef} className={classes.cards}>
                    {cards.map((card, index) =>
                        <div ref={card.ref} key={index} className={classes.card}>
                            <img className={classes.cardIcon} src={card.icon} alt=''/>
                            <p className={[classes.cardText, 'font-17'].join(' ')}>
                                <Trans>
                                    {card.text}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default WhyChoose;
