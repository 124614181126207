import React from "react";
import classes from './AffiliateProgram.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import image from '../../../assets/img/ConditionsPage/affiliate_program.png';

const AffiliateProgram = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.affiliateProgram}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-35'].join(' ')}>
                        {t("conditions_58")}
                    </h2>
                    <div className={[classes.text, 'font-17'].join(' ')}>
                        <p>
                            <b>
                                {t("conditions_59")}
                            </b>
                        </p>
                        <p>
                            {t("conditions_60")}
                        </p>
                    </div>
                    <div className={classes.btn}>
                        <Button>
                            {t("btn_start")}
                        </Button>
                    </div>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default AffiliateProgram;
