import React from "react";
import classes from "./Button.module.scss";

const Button = ({children, login}) => {
  return (
    <>
      {login ?
        <a href="https://user.avos-finance.net/login" className={`${classes.button}`}>
          <span>{children}</span>
        </a>
        :
        <a href="https://user.avos-finance.net/signup" className={`${classes.button}`}>
          <span>{children}</span>
        </a>
      }
    </>
  );
};

export default Button;
