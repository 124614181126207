import React, { useEffect, useRef } from "react";
import classes from './TradingHours.module.scss';
import { useTranslation } from "react-i18next";
import Table from "./Table";
import Cards from "./Cards";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const TradingHours = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const titleRef = useRef(null);
    const titleTextRef_01 = useRef(null);
    const titleTextRef_02 = useRef(null);
    const cardsRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            let ctx = gsap.context(() => {
                gsap.timeline().fromTo(titleTextRef_01.current, {
                    x: -150,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: titleRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(titleTextRef_02.current, {
                    x: 250,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: titleRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
                if (document.documentElement.clientWidth > 1420) {
                    gsap.to(cardsRef.current, {
                        x: -1142,
                        ease: "Power1.easeInOut",
                        scrollTrigger: {
                            trigger: bodyRef.current,
                            start: "bottom bottom",
                            end: "+=1200px",
                            scrub: 1,
                            pin: bodyRef.current,
                        }
                    });
                } else {
                    gsap.to(cardsRef.current, {
                        x: -1242,
                        scrollTrigger: {
                            trigger: bodyRef.current,
                            start: "bottom bottom",
                            end: "+=1000px",
                            scrub: 1,
                            pin: bodyRef.current,
                        }
                    });
                }
            });
            return () => ctx.revert();
        }
    }, []);
    return (
        <section ref={bodyRef} className={classes.tradingHoursWrap}>
            <div className="container">
                <div className={classes.tradingHours}>
                    <div className={classes.titleWrap}>
                        <div className={classes.title}>
                            <h2 ref={titleRef} className={classes.titleText}>
                                <div ref={titleTextRef_01} className={classes.titleText_01}>
                                    <span className={classes.tradingmain}>{t("accounts_51")}</span>
                                </div>
                                <div ref={titleTextRef_02} className={classes.titleText_02}>
                                    {t("accounts_52")}
                                </div>
                            </h2>
                        </div>
                    </div>
                    <div className={classes.tableWrap}>
                        <Table />
                    </div>
                    <div className={classes.cardsWrap}>
                        <Cards cardsRef={cardsRef} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TradingHours;
