import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/ProductsPage/banner.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});
const Banner = () => {
    const { t } = useTranslation();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(ref1.current, {
                y: 0,
                x: 150,
                scale: 0.8,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                scale: 1,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref2.current, {
                y: -0,
                x: -250,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref3.current, {
                y: 200,
                x: 120,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref4.current, {
                y: -120,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref5.current, {
                y: -120,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });

        }
    }, []);

    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-100'].join(' ')}
                    >
                        <span ref={ref1} className='font-100'>{t("products_1")}</span>
                    </h1>
                    <h2 className={classes.subtitle}>
                        <span className="font-50">
                            <span ref={ref2}>{t("products_2")}</span>
                        </span>
                        <span className="font-30">
                            <span ref={ref3}>{t("products_3")}</span>
                        </span>
                    </h2>
                    <p className={[classes.text, 'font-17'].join(' ')}>
                        <span ref={ref5}>{t("products_4")}</span>
                    </p>
                </div>
                <img ref={ref4} className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Banner;
