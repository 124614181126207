import React from 'react'
import classes from "./Card.module.scss"
import img1 from "../../../assets/img/about/2/1.png"
import {useTranslation} from "react-i18next";
const Card = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.card, "mt"].join(" ")}>
            <div className={[classes.cardBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.cardRow, ""].join(" ")}>
                        <div className={[classes.cardRowLeft, ""].join(" ")}>
                            <img src={img1} alt="About Avos Finance" />
                        </div>
                        <div className={[classes.cardRowRight, ""].join(" ")}>
                            <p className={[classes.cardRowRightText, "font-17"].join(" ")}>
                                {t("about_6")}
                            </p>
                            <p className={[classes.cardRowRightText, "font-17"].join(" ")}>
                                {t("about_7")}
                            </p>
                            <p className={[classes.cardRowRightText, "font-17"].join(" ")}>
                                {t("about_8")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card