import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import woman from '../../../assets/img/ConditionsPage/banner.png';
import Button from "../../GlobalComponents/Button/Button";
import { gsap } from "gsap";
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const textRef_01 = useRef(null);
    const textRef_02 = useRef(null);
    const textRef_03 = useRef(null);
    const imageRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.to(textRef_01.current, {
                x: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.to(textRef_02.current, {
                y: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.to(textRef_03.current, {
                y: 0,
                delay: 0.5,
                duration: 1,
            });
            gsap.to(imageRef.current, {
                y: 0,
                scale: 1,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className={classes.bannerWrap}>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={classes.title}>
                            <div className={[classes.titleText_01, 'font-50'].join(' ')}>
                                <div className={classes.textAnim_01} ref={textRef_01}>
                                    {t('cond_banner_title')}
                                </div>
                            </div>
                            <div className={[classes.titleText_02, 'font-100'].join(' ')}>
                                <div className={classes.textAnim_02} ref={textRef_02}>
                                    <span className="font-100">{t('cond_banner_subtitle')}</span>
                                </div>
                            </div>
                            <div className={[classes.titleText_03, 'font-30'].join(' ')}>
                                <div className={classes.textAnim_03} ref={textRef_03}>
                                    {t('cond_banner_text_01')}
                                </div>
                            </div>
                        </h1>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                {t('cond_banner_text_02')}
                            </p>
                            <p>
                                {t('cond_banner_text_03')}
                            </p>
                        </div>
                        <div className={classes.btn}>
                            <Button>
                                {t('btn_start')}
                            </Button>
                        </div>
                    </div>
                    <img ref={imageRef} className={classes.woman} src={woman} alt='' />
                </div>
            </div>
        </section>
    );
};

export default Banner;
