import React, { useEffect, useRef } from "react";
import classes from './ConditionsPage.module.scss';
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/ConditionsPage/Banner/Banner";
import TradingHours from "../../components/ConditionsPage/TradingHours/TradingHours";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import Partnership from "../../components/ConditionsPage/Partnership/Partnership";
import BrokerProgram from "../../components/ConditionsPage/BrokerProgram/BrokerProgram";
import StartEarning from "../../components/ConditionsPage/StartEarning/StartEarning";
import WhyBecome from "../../components/ConditionsPage/WhyBecome/WhyBecome";
import AffiliateProgram from "../../components/ConditionsPage/AffiliateProgram/AffiliateProgram";
import WhatOffer from "../../components/ConditionsPage/WhatOffer/WhatOffer";
import WhyChoose from "../../components/ConditionsPage/WhyChoose/WhyChoose";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

const ConditionsPage = () => {
  const bodyRef = useRef(null);
  const blindRef_01 = useRef(null);
  const blindRef_02 = useRef(null);
  useEffect(() => {
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().to(blindRef_01.current, {
        x: 0,
        scrollTrigger: {
          trigger: bodyRef.current,
          start: "+=1px",
          end: "+=300px",
          scrub: 1,
        }
      });
      gsap.timeline().to(blindRef_02.current, {
        x: 0,
        scrollTrigger: {
          trigger: bodyRef.current,
          start: "+=1px",
          end: "+=300px",
          scrub: 1,
        }
      });
    }
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Trading Conditions</title>
        <meta name="title" content="Transparent Trading Conditions | Avos Finance Ensures Fair and Secure Trading" />
        <meta property="og:title" content="Transparent Trading Conditions | Avos Finance Ensures Fair and Secure Trading" />
        <meta property="twitter:title" content="Transparent Trading Conditions | Avos Finance Ensures Fair and Secure Trading" />

        <meta name="description" content="Explore Avos Finance's transparent trading conditions, featuring competitive spreads, lightning-fast execution, and robust security measures. Trade with confidence and maximize your potential in the global markets." />
        <meta property="og:description" content="Explore Avos Finance's transparent trading conditions, featuring competitive spreads, lightning-fast execution, and robust security measures. Trade with confidence and maximize your potential in the global markets." />
        <meta property="twitter:description" content="Explore Avos Finance's transparent trading conditions, featuring competitive spreads, lightning-fast execution, and robust security measures. Trade with confidence and maximize your potential in the global markets." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div ref={bodyRef} className="ovf-hidden">
        <Header />
        <Banner />
        <div ref={blindRef_01} className={[classes.blind, classes.blind_01].join(' ')}></div>
        <div ref={blindRef_02} className={[classes.blind, classes.blind_02].join(' ')}></div>
        <div className={classes.pageContent}>
          <TradingHours />
          <Partnership />
          <BrokerProgram />
          <StartEarning />
          <WhyBecome />
          <AffiliateProgram />
          <WhatOffer />
          <WhyChoose />
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
};

export default ConditionsPage;
