import React, { useEffect, useRef } from "react";
import classes from './Partnership.module.scss';
import { useTranslation } from "react-i18next";
import phone from '../../../assets/img/ConditionsPage/partnership.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Partnership = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const listRef = useRef(null);
    const imageRef = useRef(null);
    const titleTextRef_01 = useRef(null);
    const titleTextRef_02 = useRef(null);
    const liTitle_01 = useRef(null);
    const liText_01 = useRef(null);
    const liTitle_02 = useRef(null);
    const liText_02 = useRef(null);
    const liTitle_03 = useRef(null);
    const liText_03 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.timeline().fromTo(imageRef.current, {
                    y: 120,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(titleTextRef_01.current, {
                    x: 140,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(titleTextRef_02.current, {
                    x: -180,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=300px",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(liTitle_01.current, {
                    x: 30,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: listRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(liText_01.current, {
                    x: 60,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: listRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(liTitle_02.current, {
                    x: 90,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: listRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(liText_02.current, {
                    x: 120,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: listRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(liTitle_03.current, {
                    x: 150,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: listRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
                gsap.timeline().fromTo(liText_03.current, {
                    x: 180,
                }, {
                    x: 0,
                    scrollTrigger: {
                        trigger: listRef.current,
                        end: "bottom bottom",
                        scrub: 3,
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.partnership}>
                <div ref={imageRef} className={classes.image}>
                    <img src={phone} alt=''/>
                </div>
                <div className={classes.content}>
                    <div className={classes.titleWrap}>
                        <h2 className={classes.title}>
                            <div ref={titleTextRef_01} className={[classes.titleText_01, 'font-120'].join(' ')}>
                                {t("conditions_32")}
                            </div>
                            <div ref={titleTextRef_02} className={[classes.titleText_02, 'font-50'].join(' ')}>
                                {t("conditions_33")}
                            </div>
                        </h2>
                    </div>
                    <div ref={listRef} className={classes.list}>
                        <div className={classes.li}>
                            <p ref={liTitle_01} className={classes.liTitle}>
                                109 000+
                            </p>
                            <p ref={liText_01} className={[classes.liText, 'font-17'].join(' ')}>
                                {t("conditions_34")}
                            </p>
                        </div>
                        <div className={classes.li}>
                            <p ref={liTitle_02} className={classes.liTitle}>
                                $1 BLN
                            </p>
                            <p ref={liText_02} className={[classes.liText, 'font-17'].join(' ')}>
                                {t("conditions_35")}
                            </p>
                        </div>
                        <div className={classes.li}>
                            <p ref={liTitle_03} className={classes.liTitle}>
                                $1.4 MLN
                            </p>
                            <p ref={liText_03} className={[classes.liText, 'font-17'].join(' ')}>
                                {t("conditions_36")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partnership;
