import React, { useEffect, useRef, useState } from "react";
import classes from './AccountInformation.module.scss';
import { useTranslation, Trans } from "react-i18next";
import img1 from "../../../assets/img/FaqPage/1/1.png";


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});
const AccountInformation = () => {
    const { t } = useTranslation();
    const tabs = [
        {
            title: 'faq_1',
            text: 'faq_2',
        },
        {
            title: 'faq_3',
            text: 'faq_4',
        },
        {
            title: 'faq_5',
            text: 'faq_6',
        },
        {
            title: 'faq_7',
            text: 'faq_8',
        },
        {
            title: 'faq_9',
            text: 'faq_10',
        },
        {
            title: 'faq_11',
            text: 'faq_12',
        },
        {
            title: 'faq_13',
            text: 'faq_14',
        },
        {
            title: 'faq_15',
            text: 'faq_16',
        },
    ];
    const ref1 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(ref1.current, {
                y: 0,
                x: 150,
                scale: 0.8,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                scale: 1,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });

        }
    }, []);
    const [currentTab, setCurrentTab] = useState(0);
    const setTab = (value) => {
        if (currentTab === value) {
            setCurrentTab(-1);
            return;
        }
        setCurrentTab(value);
    };
    return (
        <section className="mt container">
            <div className={classes.container}>
                <div className={classes.header}>
                    <img ref={ref1} src={img1} alt="" />
                </div>
                <div className={classes.tabs}>
                    {tabs.map((tab, index) =>
                        <div
                            className={`${classes.tab} ${index === currentTab && classes.tabActive} no-select`}
                            onClick={() => setTab(index)}
                            key={index}
                        >
                            <div className={classes.status}>
                                <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.25278 8.38332L7.25269 8.38324L7.2444 8.39365C7.15543 8.50541 7.0424 8.59567 6.91371 8.6577C6.78504 8.71974 6.64402 8.75195 6.50117 8.75195C6.35831 8.75195 6.2173 8.71974 6.08862 8.6577C5.95994 8.59567 5.84691 8.50541 5.75793 8.39365L5.75802 8.39357L5.74956 8.38332L1.54342 3.288C1.38704 3.09137 1.28861 2.85498 1.25925 2.60541C1.2297 2.35422 1.2713 2.09979 1.37932 1.8711L1.3835 1.86224L1.38745 1.85328C1.46566 1.67586 1.59328 1.52469 1.75508 1.41784C1.91584 1.31169 2.10358 1.25373 2.29615 1.25077L10.7062 1.25077C10.8988 1.25373 11.0865 1.31169 11.2473 1.41784C11.409 1.52469 11.5367 1.67585 11.6149 1.85328L11.6148 1.8533L11.6184 1.86124C11.7045 2.05043 11.7497 2.25561 11.7512 2.46342C11.7511 2.76281 11.6485 3.05315 11.4605 3.28609L7.25278 8.38332Z" stroke="#000" stroke-width="1.5" />
                                </svg>

                            </div>
                            <div className={[classes.tabTitle, 'font-17'].join(' ')}>
                                <Trans>{tab.title}</Trans>
                            </div>
                            <span></span>
                            <div className={classes.tabText}>
                                <Trans>{tab.text}</Trans>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default AccountInformation;
