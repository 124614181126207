import React, { useEffect, useRef } from "react";
import classes from './StartEarning.module.scss';
import { Trans, useTranslation } from "react-i18next";
import image from '../../../assets/img/ConditionsPage/start_earning.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const StartEarning = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const imageRef = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            setTimeout(() => {
                gsap.timeline().fromTo(imageRef.current, {
                    y: 150,
                }, {
                    y: 0,
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        end: "+=400px",
                        scrub: 3,
                    }
                });
            }, 300);
        }
    }, []);
    return (
        <section className="mt container">
            <div ref={bodyRef} className={classes.startEarningContainer}>
                <div className={classes.startEarning}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-35'].join(' ')}>
                            {t("conditions_44")}
                        </h2>
                        <div className={[classes.text, 'font-17'].join(' ')}>
                            <p>
                                <Trans>
                                    {t("conditions_45")}
                                </Trans>
                            </p>
                            <p>
                                <Trans>
                                    {t("conditions_46")}
                                </Trans>
                            </p>
                            <p>
                                <Trans>
                                    {t("conditions_47")}
                                </Trans>
                            </p>
                            <p>
                                <Trans>
                                    {t("conditions_48")}
                                </Trans>
                            </p>
                        </div>
                    </div>
                    <img ref={imageRef} className={classes.image} src={image} alt=''/>
                </div>
            </div>
        </section>
    );
};

export default StartEarning;
