import React from "react";
import classes from "./Footer.module.scss";
import logo from "../../../assets/img/logo.svg";
import { useTranslation, Trans } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const { hostname } = document.location;
  return (
    <footer className="mt container">
      <div className={classes.footer}>
        <div className={classes.logo}>
          <img src={logo} alt="" />
        </div>
        <div className={classes.info}>
          <div className={classes.contacts}>
            <p>
              {t("footer_1")}
            </p>
            <a href={`mailto:support@${hostname}`}>support@{hostname}</a>
            <a href="tel:+441863440181">+441863440181</a>
          </div>
          <div className={classes.docs}>
            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
              {t("footer_5")}
            </a>
            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
              {t("footer_6")}
            </a>
            <a href="/docs/AML_POLICY.pdf" target="_blank">
              {t("footer_7")}
            </a>
          </div>
        </div>
        <div className={classes.disclaimer}>
          <p className={classes.disclaimerTitle}>
            {t("footer_2")}
          </p>
          <p>
            <Trans>footer_3</Trans>
          </p>
          <p className={classes.rights}>
            <Trans>footer_4</Trans>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
