import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/ProductsPage/Banner/Banner";
import Tabs from "../../components/ProductsPage/Tabs/Tabs";
import HomeOurPartners from "../../components/MainComponents/HomeOurPartners/HomeOurPartners";

const ProductsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Trading Products</title>
        <meta name="title" content="Diverse Trading Products | Avos Finance Delivers a World of Investment Opportunities" />
        <meta property="og:title" content="Diverse Trading Products | Avos Finance Delivers a World of Investment Opportunities" />
        <meta property="twitter:title" content="Diverse Trading Products | Avos Finance Delivers a World of Investment Opportunities" />

        <meta name="description" content="Discover a wide range of trading products on Avos Finance, including stocks, currencies, metals, and indices. Take advantage of diverse market opportunities and execute your trading strategies flawlessly." />
        <meta property="og:description" content="Discover a wide range of trading products on Avos Finance, including stocks, currencies, metals, and indices. Take advantage of diverse market opportunities and execute your trading strategies flawlessly." />
        <meta property="twitter:description" content="Discover a wide range of trading products on Avos Finance, including stocks, currencies, metals, and indices. Take advantage of diverse market opportunities and execute your trading strategies flawlessly." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Tabs />
        <HomeOurPartners />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default ProductsPage;
