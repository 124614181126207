import logo from '../assets/img/logo.svg';
import img1 from '../assets/img/AccountsPage/banner.png';
import img2 from '../assets/img/ConditionsPage/banner.png';
import img3 from '../assets/img/FaqPage/1/1.png';
import img4 from '../assets/img/ProductsPage/banner.png';
import img5 from '../assets/img/about/1/1.png';
import img6 from '../assets/img/about/1/2.png';
import img7 from '../assets/img/about/1/3.png';
import img8 from '../assets/img/about/1/4.png';
import img9 from '../assets/img/about/1/mob.png';
import img10 from '../assets/img/main/1/1.png';
import img11 from '../assets/img/main/1/2.png';
import img12 from '../assets/img/main/1/item1.png';
import img13 from '../assets/img/main/1/item2.png';
import img14 from '../assets/img/main/1/item3.png';
import img15 from '../assets/img/main/1/item4.png';
import img16 from '../assets/img/main/1/item5.png';
import img17 from '../assets/img/main/1/mob.png';


export const images = [
    logo, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17
];
