import React from 'react'
import classes from "./Will.module.scss"
import img1 from "../../../assets/img/main/2/1.png"
import img2 from "../../../assets/img/main/2/2.png"
import img3 from "../../../assets/img/main/2/3.png"
import img4 from "../../../assets/img/main/2/4.png"
import img5 from "../../../assets/img/main/2/5.png"
import {useTranslation} from "react-i18next";
const Will = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.will, "mt"].join(" ")}>
            <div className={[classes.willBody, ""].join(" ")}>
                <div className="container">
                    <h2 className={[classes.willTitle, "font-35"].join(" ")}>
                        {t("home_32")}
                    </h2>
                    <div className={[classes.willRow, ""].join(" ")}>
                        <div className={[classes.willRowColumn, ""].join(" ")}>
                            <div className={[classes.willRowColumnItem, ""].join(" ")}>
                                <div className={[classes.willRowColumnItemImg, ""].join(" ")}>
                                    <img src={img1} alt="Why Clients Will Love Us" />
                                </div>
                                <p className={[classes.willRowColumnItemText, "font-17"].join(" ")}>
                                    {t("home_33")} <span></span> {t("home_34")}
                                </p>
                            </div>
                        </div>
                        <div className={[classes.willRowColumn, ""].join(" ")}>
                            <div className={[classes.willRowColumnItem, ""].join(" ")}>
                                <div className={[classes.willRowColumnItemImg, ""].join(" ")}>
                                    <img src={img2} alt="Why Clients Will Love Us" />
                                </div>
                                <p className={[classes.willRowColumnItemText, "font-17"].join(" ")}>
                                    {t("home_35")} <span></span>{t("home_36")}
                                </p>
                            </div>
                        </div>
                        <div className={[classes.willRowColumn, ""].join(" ")}>
                            <div className={[classes.willRowColumnItem, ""].join(" ")}>
                                <div className={[classes.willRowColumnItemImg, ""].join(" ")}>
                                    <img src={img3} alt="Why Clients Will Love Us" />
                                </div>
                                <p className={[classes.willRowColumnItemText, "font-17"].join(" ")}>
                                    {t("home_37")} <span></span> {t("home_38")}
                                </p>
                            </div>
                        </div>
                        <div className={[classes.willRowColumn, ""].join(" ")}>
                            <div className={[classes.willRowColumnItem, ""].join(" ")}>
                                <div className={[classes.willRowColumnItemImg, ""].join(" ")}>
                                    <img src={img4} alt="Why Clients Will Love Us" />
                                </div>
                                <p className={[classes.willRowColumnItemText, "font-17"].join(" ")}>
                                    {t("home_39")} <span></span> {t("home_40")}
                                </p>
                            </div>
                        </div>
                        <div className={[classes.willRowColumn, ""].join(" ")}>
                            <div className={[classes.willRowColumnItem, ""].join(" ")}>
                                <div className={[classes.willRowColumnItemImg, ""].join(" ")}>
                                    <img src={img5} alt="Why Clients Will Love Us" />
                                </div>
                                <p className={[classes.willRowColumnItemText, "font-17"].join(" ")}>
                                    {t("home_41")} <span></span> {t("home_42")}
                                </p>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default Will