import React from 'react'
import classes from "./Our.module.scss"
import Button from '../../GlobalComponents/Button/Button'
import img from '../../../assets/img/main/12.png'
import {useTranslation} from "react-i18next";
const Our = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.our, "mt"].join(" ")}>
            <div className={[classes.ourBody, ""].join(" ")}>
                <div className="container">
                    <h3 className={[classes.ourTitle, "font-35"].join(" ")}>
                        {t("home_52")}
                    </h3>
                    <div className={[classes.ourRow, ""].join(" ")}>
                        <div className={[classes.ourRowLeft, ""].join(" ")}>
                            <h4 className={[classes.ourRowLeftTitle, "font-17"].join(" ")}>
                                {t("home_53")}
                            </h4>
                            <p className={[classes.ourRowLeftSubTitle, "font-17"].join(" ")}>
                                {t("home_54")}
                            </p>
                            <p className={[classes.ourRowLeftText, "font-17"].join(" ")}>
                                {t("home_55")}
                            </p>
                        </div>
                        <div className={[classes.ourRowRight, ""].join(" ")}>
                            <img src={img} alt="" />
                        </div>
                    </div>
                    <div className={[classes.ourBtn, ""].join(" ")}>
                        <Button>{t("btn_learn")}</Button>
                    </div>


                </div>
            </div>

        </div>
    )
}

export default Our
