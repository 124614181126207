import React, { useState } from "react";
import classes from "./Comprehensive.module.scss";

import Button from "../../GlobalComponents/Button/Button";
import {useTranslation, Trans} from "react-i18next";
const Comprehensive = () => {
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([
    {
      id: 1,
      active: true,
      title: "about_9",
      text: ["about_10", "about_11", "about_12"]
    },
    {
      id: 2,
      active: false,
      title: "about_13",
      text: ["about_14", "about_15", "about_16"]
    },
    {
      id: 3,
      active: false,
      title: "about_17",
      text: ["about_18", "about_19"]
    },
    {
      id: 4,
      active: false,
      title: "about_20",
      text: ["about_21", "about_22", "about_23"]
    },

  ]);
  return (
    <div className={[classes.comprehensive, "mt"].join(" ")}>
      <div className={[classes.comprehensiveBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.comprehensiveTitle, "font-35"].join(" ")}>
            {t("about_24")}
          </h2>
          <div className={[classes.comprehensiveRow, ""].join(" ")}>
            <div className={[classes.comprehensiveRowLeft, ""].join(" ")}>
              {tabs.map((btn) => {
                return (
                  <p
                    key={`btn-${btn.id}`}
                    onClick={() => {
                      setTabs(
                        tabs.map((subI) => {
                          if (btn.id === subI.id) {
                            return { ...subI, active: true };
                          } else {
                            return { ...subI, active: false };
                          }
                        })
                      );
                    }}
                    className={
                      btn.active
                        ? [
                          classes.comprehensiveRowLeftBtn,
                          classes.active,
                          "font-20",
                        ].join(" ")
                        : [classes.comprehensiveRowLeftBtn, "font-20"].join(" ")
                    }
                  >
                    <Trans>{btn.title}</Trans>
                  </p>
                );
              })}
            </div>
            <div className={[classes.comprehensiveRowRight, ""].join(" ")}>
              {tabs.map((textI) => {
                return (
                  <div>
                    {textI.text.map((i, index) => {
                      return (
                        <p
                          key={`text-${index}`}
                          className={
                            textI.active
                              ? [
                                classes.comprehensiveRowRightText,
                                classes.active,
                                "font-17",
                              ].join(" ")
                              : [classes.comprehensiveRowRightText, "font-17"].join(
                                " "
                              )
                          }
                        >
                          <Trans>{i}</Trans>
                        </p>
                      )
                    })}
                  </div>


                );
              })}

            </div>
          </div>
          <div className={[classes.comprehensiveMob, ""].join(" ")}>
            {tabs.map((mobI) => {
              return (
                <div
                  className={[classes.comprehensiveMobItem, ""].join(" ")}
                  key={`mob-${mobI.id}`}
                >
                  <h3
                    className={[
                      classes.comprehensiveMobItemTitle,
                      "font-17",
                    ].join(" ")}
                  >
                    <Trans>{mobI.title}</Trans>
                  </h3>
                  {mobI.text.map((i) => {
                    return (
                      <p
                        key={`subtext-${i}`}
                        className={

                          [classes.comprehensiveMobItemText, "font-17"].join(
                            " "
                          )
                        }
                      >
                        <Trans>{i}</Trans>
                      </p>
                    )
                  })}

                </div>
              );
            })}

          </div>
        </div>
      </div>
    </div>
  );
};

export default Comprehensive;
