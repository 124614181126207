import React, { useEffect, useRef } from "react";
import classes from './Banner.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/AccountsPage/banner.png';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});
const Banner = () => {
    const { t } = useTranslation();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(ref1.current, {
                y: 0,
                x: 150,
                scale: 0.8,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                scale: 1,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref2.current, {
                y: -0,
                x: -250,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref3.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.4,
                duration: 1,
            });
            gsap.fromTo(ref4.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.7,
                duration: 1,
            });
            gsap.fromTo(ref5.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 1,
                duration: 1,
            });
            gsap.fromTo(ref6.current, {
                y: 120,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.5,
                duration: 1,
            });
        }
    }, []);
    return (
        <section className="container">
            <div className={classes.banner}>
                <div className={classes.content}>
                    <h1 className={[classes.title, 'font-35'].join(' ')}>
                        <span className={classes.orange}>
                            <span ref={ref1}>{t("accounts_1")} </span>

                        </span>
                        <span>
                            <span ref={ref2}>{t("accounts_2")}</span>

                        </span>
                    </h1>
                    <div className={[classes.text, 'font-17'].join(' ')}>
                        <p>
                            <span ref={ref3}>{t("accounts_3")}</span>

                        </p>
                        <p>
                            <span ref={ref4}>{t("accounts_4")}</span>

                        </p>
                        <p>
                            <span ref={ref5}>{t("accounts_5")}</span>

                        </p>
                    </div>
                </div>
                <img ref={ref6} className={classes.image} src={image} alt='' />
            </div>
        </section>
    );
};

export default Banner;
