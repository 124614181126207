import React from "react";
import classes from './OurRewards.module.scss';
import { useTranslation } from "react-i18next";
import award_01 from '../../../assets/img/HomePage/OurRewards/award_01.png';
import award_02 from '../../../assets/img/HomePage/OurRewards/award_02.png';
import award_03 from '../../../assets/img/HomePage/OurRewards/award_03.png';
import award_04 from '../../../assets/img/HomePage/OurRewards/award_04.png';
import award_05 from '../../../assets/img/HomePage/OurRewards/award_05.png';
import Marquee from "react-fast-marquee";

const awards = [
    award_01,
    award_02,
    award_03,
    award_04,
    award_05,
];

const OurRewards = () => {
    const { t } = useTranslation();
    return (
        <section className="container">
            <div className={classes.ourRewards}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t("home_99")}
                </h2>
                <div className={classes.awards}>
                    {awards.map((award, index) =>
                        <img key={index} className={classes.award} src={award} alt='' />
                    )}
                </div>
                <div className={classes.marqueWrap}>
                    <Marquee
                        pauseOnHover
                        autoFill
                    >
                        {awards.map((award, index) =>
                            <img key={index} className={classes.award} src={award} alt='' />
                        )}
                    </Marquee>
                </div>
            </div>
        </section>
    );
};

export default OurRewards;
