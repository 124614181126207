import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import AccountInformation from "../../components/FaqPage/AccountInformation/AccountInformation";

const FaqPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>FAQ</title>
        <meta name="title" content="Frequently Asked Questions and Expert Support | Avos Finance Helps You Succeed" />
        <meta property="og:title" content="Frequently Asked Questions and Expert Support | Avos Finance Helps You Succeed" />
        <meta property="twitter:title" content="Frequently Asked Questions and Expert Support | Avos Finance Helps You Succeed" />

        <meta name="description" content="Get answers to your queries and access expert support at Avos Finance. Our comprehensive FAQ section and dedicated support team are here to assist you on your trading journey." />
        <meta property="og:description" content="Get answers to your queries and access expert support at Avos Finance. Our comprehensive FAQ section and dedicated support team are here to assist you on your trading journey." />
        <meta property="twitter:description" content="Get answers to your queries and access expert support at Avos Finance. Our comprehensive FAQ section and dedicated support team are here to assist you on your trading journey." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <AccountInformation />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default FaqPage;
