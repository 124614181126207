import React, { useState } from "react";
import classes from './AccountTypes.module.scss';
import { useTranslation, Trans } from "react-i18next";
import Button from '../../GlobalComponents/Button/Button';

const AccountTypes = () => {
    const { t } = useTranslation();
    const rows = [
        'accounts_6',
        'accounts_7',
        'accounts_8',
        'accounts_9',
        'accounts_10',
        'accounts_11',
        'accounts_12',
        'accounts_13',
        'accounts_14',
        'accounts_15',
        'accounts_16',
        'accounts_17',
        'accounts_18',
    ];

    const accounts = [
        {
            title: 'Standard',
            rows: [
                '$5000',
                'accounts_20',
                'accounts_21',
                'accounts_22',
                'accounts_23',
                '0.01',
                '200 (7:00 - 20:59 GMT+0), 20 (21:00 - 6:59 GMT+0)',
                'accounts_24',
                '0%',
                '60%',
                '0%',
                'accounts_25',
                'accounts_26',
            ],
        },
        {
            title: 'Silver',
            rows: [
                '$25000',
                'accounts_28',
                'accounts_29',
                'accounts_30',
                'accounts_31',
                '0.01',
                '200 (7:00 - 20:59 GMT+0), 20 (21:00 - 6:59 GMT+0)',
                'accounts_32',
                '0%',
                '30%',
                '0%',
                'accounts_33',
                'accounts_34',
            ],
        },
        {
            title: 'Gold',
            rows: [
                '$50000',
                'accounts_36',
                'accounts_37',
                'accounts_38',
                'accounts_39',
                '0.01',
                '200 (7:00 - 20:59 GMT+0), 20 (21:00 - 6:59 GMT+0)',
                'accounts_40',
                '0%',
                '30%',
                '0%',
                'accounts_41',
                'accounts_42',
            ],
        },
        {
            title: 'Platinum',
            rows: [
                '$100000',
                'accounts_44',
                'accounts_45',
                'accounts_46',
                'accounts_47',
                '0.01',
                '200 (7:00 - 20:59 GMT+0), 20 (21:00 - 6:59 GMT+0)',
                'accounts_48',
                '0%',
                '30%',
                '0%',
                'accounts_49',
                'accounts_50',
            ],
        },
        {
            title: 'Diamond',
            rows: [
                '$250000',
                'accounts_44',
                'accounts_45',
                'accounts_46',
                'accounts_47',
                '0.01',
                '200 (7:00 - 20:59 GMT+0), 20 (21:00 - 6:59 GMT+0)',
                'accounts_48',
                '0%',
                '30%',
                '0%',
                'accounts_49',
                'accounts_50',
            ],
        },
    ];
    const [ current, setCurrent ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.accountTypes}>
                <div className={classes.buttons}>
                    {accounts.map((account, index) =>
                        <div
                            key={index}
                            onClick={() => setCurrent(index)}
                            className={`${classes.button} ${current === index && classes.buttonActive} no-select font-17`}
                        >
                            <span>
                                <Trans>{account.title}</Trans>
                            </span>
                        </div>
                    )}
                </div>
                <div className={classes.card}>
                    <h4 className={classes.title}>
                        <Trans>{accounts[current].title}</Trans>
                    </h4>
                    <ul className={classes.rows}>
                        {accounts[current].rows.map((row, index) =>
                            <li key={index} className={classes.row}>
                                <span>
                                    <b>
                                        <Trans>{rows[index]}</Trans>:&nbsp;
                                    </b>
                                </span>
                                <span>
                                    <Trans>{row}</Trans>
                                </span>
                            </li>
                        )}
                    </ul>
                    <div className={classes.btn}>
                        <Button>
                            {t("btn_open")}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AccountTypes;
