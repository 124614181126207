import React from "react";
import classes from './TradingHours.module.scss';
import { useTranslation, Trans } from "react-i18next";

const Cards = ({ cardsRef }) => {
    const { t } = useTranslation();
    const cards = [
        {
            title: 'conditions_20',
            text: 'conditions_21',
        },
        {
            title: 'conditions_22',
            text: 'conditions_23',
        },
        {
            title: 'conditions_24',
            text: 'conditions_25',
        },
        {
            title: 'conditions_26',
            text: 'conditions_27',
        },
        {
            title: 'conditions_28',
            text: 'conditions_29',
        },
        {
            title: 'conditions_30',
            text: 'conditions_31',
        },
    ];
    return (
        <div className={classes.cardsContainer}>
            <div ref={cardsRef} className={classes.cards}>
                {cards.map((card, index) =>
                    <div key={index} className={classes.card}>
                        <h4 className={[classes.cardTitle, 'font-25-cards'].join(' ')}>
                            <Trans>{card.title}</Trans>
                        </h4>
                        <p className={[classes.cardText, 'font-16'].join(' ')}>
                            <Trans>{card.text}</Trans>
                        </p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Cards;
