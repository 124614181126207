import React from 'react'
import classes from "./Make.module.scss"
import {useTranslation} from "react-i18next";
const Make = () => {
    const { t } = useTranslation();
    return (
        <div className={[classes.make, "mt"].join(" ")}>
            <div className={[classes.makeBody, ""].join(" ")}>
                <div className="container">
                    <h2 className={[classes.makeTitle, "font-35"].join(" ")}>
                        {t("home_24")}
                    </h2>
                    <div className={[classes.makeRow, ""].join(" ")}>
                        <div className={[classes.makeRowColumn, ""].join(" ")}>
                            <div className={[classes.makeRowColumnItem, ""].join(" ")}>
                                <h3 className={[classes.makeRowColumnTitle, "font-20"].join(" ")}>
                                    {t("home_25")}
                                    <span></span>
                                    {t("home_26")}
                                </h3>
                                <p className={[classes.makeRowColumnText, "font-17"].join(" ")}>
                                    {t("home_27")}
                                </p>
                            </div>


                        </div>
                        <div className={[classes.makeRowColumn, ""].join(" ")}>
                            <div className={[classes.makeRowColumnItem, ""].join(" ")}>
                                <h3 className={[classes.makeRowColumnTitle, "font-20"].join(" ")}>
                                    {t("home_28")}
                                </h3>
                                <p className={[classes.makeRowColumnText, "font-17"].join(" ")}>
                                    {t("home_29")}
                                </p>
                            </div>


                        </div>
                        <div className={[classes.makeRowColumn, ""].join(" ")}>
                            <div className={[classes.makeRowColumnItem, ""].join(" ")}>
                                <h3 className={[classes.makeRowColumnTitle, "font-20"].join(" ")}>
                                    {t("home_30")}
                                </h3>
                                <p className={[classes.makeRowColumnText, "font-17"].join(" ")}>
                                    {t("home_31")}
                                </p>
                                <div className={[classes.makeRowColumnBtn, ""].join(" ")}>
                                    <a href="https://user.avos-finance.net/login">
                                        <svg width="61" height="45" viewBox="0 0 61 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="61" height="45" rx="20" fill="#252525" />
                                            <path d="M29.4682 31.2155L36.2358 24.3622C36.4781 24.1174 36.6703 23.8266 36.8014 23.5065C36.9325 23.1864 37 22.8433 37 22.4967C37 22.1502 36.9325 21.807 36.8014 21.4869C36.6703 21.1668 36.4781 20.8761 36.2358 20.6313L29.4682 13.778C27.822 12.111 25 13.3017 25 15.6567L25 29.3633C25 31.7183 27.822 32.8825 29.4682 31.2155Z" fill="white" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Make
