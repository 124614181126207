import React, { useEffect, useRef } from "react";
import classes from "./Banner.module.scss"
import img1 from "../../../assets/img/about/1/1.png"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import {useTranslation} from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const ref7 = useRef(null);
    const ref8 = useRef(null);
    const ref9 = useRef(null);
    const ref10 = useRef(null);

    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {

            gsap.fromTo(ref2.current, {
                y: -0,
                x: -250,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref3.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.4,
                duration: 1,
            });
            gsap.fromTo(ref4.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.7,
                duration: 1,
            });
            gsap.fromTo(ref5.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 1,
                duration: 1,
            });
            gsap.fromTo(ref6.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 1.3,
                duration: 1,
            });
            gsap.fromTo(ref7.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref8.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.7,
                duration: 1,
            });
            gsap.fromTo(ref9.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.5,
                duration: 1,
            });
            gsap.fromTo(ref10.current, {
                y: 200,
                x: 0,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
        }
    }, []);
    return (
        <div className={[classes.banner, ""].join(" ")}>


            <div className={[classes.bannerBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.bannerRow, ""].join(" ")}>
                        <div className={[classes.bannerRowLeft, ""].join(" ")}>
                            <h2 className={[classes.bannerRowLeftTitle, "font-35"].join(" ")}>
                                <span ref={ref2}>{t("about_1")}</span>
                            </h2>
                            <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                                <span ref={ref3}>{t("about_2")}</span>
                            </p>
                            <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                                <span ref={ref4}>{t("about_3")}</span>

                            </p>
                            <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                                <span ref={ref5}>{t("about_4")}</span>

                            </p>
                            <p className={[classes.bannerRowLeftText, "font-17"].join(" ")}>
                                <span ref={ref6}>{t("about_5")}</span>

                            </p>
                        </div>
                        <div className={[classes.bannerRowRight, ""].join(" ")}>
                            <div className={[classes.bannerRowRightImgs, ""].join(" ")}>
                                <div className={[classes.bannerRowRightImgsImg4, ""].join(" ")} ref={ref10}>
                                    <img src={img1} alt="About Avos Finance" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={[classes.mob, ""].join(" ")}>
                        <img src={img1} alt="About Avos Finance" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner