import React, { useState } from "react";
import classes from './BrokerProgram.module.scss';
import { useTranslation } from "react-i18next";

const BrokerProgram = () => {
    const { t } = useTranslation();
    const [ value, setValue ] = useState(52);

    const rangeInputs = document.querySelectorAll('input[type="range"]')
     function handleInputChange(e) {
        let target = e.target
        if (e.target.type !== 'range') {
            target = document.getElementById('range')
        } 
        const min = target.min
        const max = target.max
        const val = target.value
        target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
    }
    rangeInputs.forEach(input => {
        input.addEventListener('input', handleInputChange)
    })

    return (
        <section className="mt container">
            <div className={classes.brokerProgram}>
                <div className={classes.block}>
                    <h2 className={[classes.title, 'font-35'].join(' ')}>
                        {t("conditions_37")}
                    </h2>
                    <div className={[classes.text, 'font-17'].join(' ')}>
                        <p>
                            <b>
                                {t("conditions_38")}
                            </b>
                        </p>
                        <p>
                            {t("conditions_39")}
                        </p>
                        <p>
                            {t("conditions_40")}
                        </p>
                        <p>
                            {t("conditions_41")}
                        </p>
                    </div>
                </div>
                <div className={classes.block}>
                    <div className={classes.rangeData}>
                        <div className={classes.col}>
                            <div className={classes.colTitle}>
                                {value}
                            </div>
                            <p className={classes.colText}>
                                {t("conditions_42")}
                            </p>
                        </div>
                        <div className={classes.col}>
                            <div className={classes.colTitle}>
                                ${value * 60}
                            </div>
                            <p className={classes.colText}>
                                {t("conditions_43")}
                            </p>
                        </div>
                    </div>  
                    <div className={classes.rangeWrap}>
                        <input
                            className={classes.inputRange}
                            id="range"
                            type="range"
                            onChange={e => setValue(e.target.value)}
                            value={value}
                            min={10}
                            max={400}
                        />
                        <div className={classes.rangeWrapValues}>
                            <span className={classes.rangeWrapValuesItem}>
                                10
                            </span>
                            <span className={classes.rangeWrapValuesItem}>
                                100
                            </span>
                            <span className={classes.rangeWrapValuesItem}>
                                200
                            </span>
                            <span className={classes.rangeWrapValuesItem}>
                                300
                            </span>
                            <span className={classes.rangeWrapValuesItem}>
                                400
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BrokerProgram;
