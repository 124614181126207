import React, { useState } from "react";
import classes from './Tabs.module.scss';
import { Trans, useTranslation } from "react-i18next";

const Tabs = () => {
    const { t } = useTranslation();
    const tabs = [
        {
            title: 'products_5',
            rows: [
                [
                    'products_6',
                    '1.4',
                    '$0',
                    '0.05%',
                    '−0.33',
                    '0',
                    '0',
                ],
                [
                    'products_7',
                    '2.3',
                    '$0',
                    '0.05%',
                    '0',
                    '−0.304594',
                    '0',
                ],
                [
                    'products_8',
                    '1',
                    '$0',
                    '0.05%',
                    '−0.62',
                    '0',
                    '0',
                ],
                [
                    'products_9',
                    '1.2',
                    '$0',
                    '0.05%',
                    '−0.31',
                    '0',
                    '0',
                ],
                [
                    'products_10',
                    '2',
                    '$0',
                    '0.05%',
                    '−0.17',
                    '0',
                    '0',
                ],
                [
                    'products_11',
                    '2.1',
                    '$0',
                    '0.05%',
                    '0',
                    '−0.33',
                    '0',
                ],
            ],
        },
        {
            title: 'products_12',
            rows: [
                [
                    'products_13',
                    '8.3',
                    '$0',
                    '0.05%',
                    '−0.4519',
                    '0',
                    '0',
                ],
                [
                    'products_14',
                    '4.9',
                    '$0',
                    '0.05%',
                    '−0.272',
                    '0',
                    '0',
                ],
                [
                    'products_15',
                    '4.2',
                    '$0',
                    '0.05%',
                    '−0.3129',
                    '0',
                    '0',
                ],
                [
                    'products_16',
                    '3.8',
                    '$0',
                    '0.05%',
                    '−0.4066',
                    '0',
                    '0',
                ],
                [
                    'products_17',
                    '454',
                    '$0',
                    '0.05%',
                    '−32.244',
                    '0',
                    '0',
                ],
                [
                    'products_18',
                    '329.2',
                    '$0',
                    '0.05%',
                    '−16.6125',
                    '0',
                    '0',
                ],
            ],
        },
        {
            title: 'products_19',
            rows: [
                [
                    'products_20',
                    '32.7',
                    '$0',
                    '5%',
                    '0',
                    '0',
                    '0',
                ],
                [
                    'products_21',
                    '23.8',
                    '$0',
                    '5%',
                    '0',
                    '0',
                    '0',
                ],
                [
                    'products_22',
                    '12.8',
                    '$0',
                    '5%',
                    '0',
                    '0',
                    '0',
                ],
                [
                    'products_23',
                    '45.5',
                    '$0',
                    '5%',
                    '0',
                    '0',
                    '0',
                ],
                [
                    'products_24',
                    '6.2',
                    '$0',
                    '5%',
                    '0',
                    '0',
                    '0',
                ],
                [
                    'products_25',
                    '121.6',
                    '$0',
                    '0.5%',
                    '0',
                    '0',
                    '0',
                ],
            ],
        },
        {
            title: 'products_26',
            rows: [
                [
                    'products_27',
                    '52.8',
                    '$0',
                    '0.5%',
                    '0.146 AUD',
                    '−0.146 AUD',
                    '0',
                ],
                [
                    'products_28',
                    '5.7',
                    '$0',
                    '0.5%',
                    '0 EUR',
                    '0 EUR',
                    '0',
                ],
                [
                    'products_29',
                    '33.4',
                    '$0',
                    '0.5%',
                    '0 EUR',
                    '0 EUR',
                    '0',
                ],
                [
                    'products_30',
                    '27',
                    '$0',
                    '0.5%',
                    '3.145 HKD',
                    '−3.145 HKD',
                    '0',
                ],
                [
                    'products_31',
                    '103.9',
                    '$0',
                    '0.5%',
                    '0 USD',
                    '0 USD',
                    '0',
                ],
                [
                    'products_32',
                    '16.2',
                    '$0',
                    '0.5%',
                    '0 JPY',
                    '0 JPY',
                    '0',
                ],
            ],
        },
        {
            title: 'products_33',
            rows: [
                [
                    'products_34',
                    '0.9',
                    '$0',
                    '5%',
                    '0',
                    '0',
                ],
                [
                    'products_35',
                    '1.1',
                    '$0',
                    '5%',
                    '0',
                    '0',
                ],
                [
                    'products_36',
                    '1',
                    '$0',
                    '5%',
                    '0',
                    '0',
                ],
                [
                    'products_37',
                    '4.1',
                    '$0',
                    '5%',
                    '0',
                    '0',
                ],
                [
                    'products_38',
                    '1.4',
                    '$0',
                    '5%',
                    '0',
                    '0',
                ],
                [
                    'products_39',
                    '1.1',
                    '$0',
                    '5%',
                    '0',
                    '0',
                ],
            ],
        },
    ];
    const [ currentTab, setCurrentTab ] = useState(0);
    return (
        <section className="mt container">
            <div className={classes.tabsWrap}>
                <div className={classes.buttons}>
                    {tabs.map((tab, index) =>
                        <div 
                            key={index} 
                            className={`${classes.button} ${currentTab === index && classes.buttonActive} no-select`}
                            onClick={() => setCurrentTab(index)}
                        >
                            <span>
                                <Trans>{tab.title}</Trans>
                            </span>
                        </div>
                    )}
                </div>
                <div className={classes.tableWrap}>
                    <table>
                        <tbody>
                            {(currentTab === 0 || currentTab === 1 || currentTab === 2) &&
                                <tr>
                                    <td>
                                        <span>
                                            <Trans>products_40</Trans>
                                        </span>
                                    </td>
                                    <td>
                                        <Trans>products_41</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_42</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_43</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_44</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_45</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_46</Trans>
                                    </td> 
                                </tr>
                            }
                            {currentTab === 3 &&
                                <tr>
                                    <td>
                                        <span>
                                            {t("products_47")}
                                        </span>
                                    </td>
                                    <td>
                                        <Trans>products_48</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_49</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_50</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_51</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_52</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_53</Trans>
                                    </td> 
                                </tr>
                            }
                            {currentTab === 4 &&
                                <tr>
                                    <td>
                                        <span>
                                            {t("products_54")}
                                        </span>
                                    </td>
                                    <td>
                                        <Trans>products_55</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_56</Trans>
                                    </td> 
                                    <td>
                                        <span><Trans>products_57</Trans></span>
                                    </td> 
                                    <td>
                                        <Trans>products_58</Trans>
                                    </td> 
                                    <td>
                                        <Trans>products_59</Trans>
                                    </td> 
                                </tr>
                            }
                            {tabs[currentTab].rows.map((row, rowIndex) =>
                                <tr key={rowIndex}>
                                    {row.map((col, colIndex) =>
                                        <td key={colIndex}>
                                            <Trans>
                                                {col}
                                            </Trans>
                                        </td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};

export default Tabs;
