import React from "react";
import classes from './WhatOffer.module.scss';
import { useTranslation } from "react-i18next";

const WhatOffer = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.whatOffer}>
                <h2 className={[classes.title, 'font-35'].join(' ')}>
                    {t("conditions_61")}
                </h2>
                <div className={classes.content}>
                    <div className={classes.col}>
                        <h4 className={[classes.colTitle, 'font-17'].join(' ')}>
                            {t("conditions_62")}
                        </h4>
                        <ul className="font-17">
                            <li>
                                {t("conditions_63")}
                            </li>
                            <li>
                                {t("conditions_64")}
                            </li>
                            <li>
                                {t("conditions_65")}
                            </li>
                            <li>
                                {t("conditions_66")}
                            </li>
                            <li>
                                {t("conditions_67")}
                            </li>
                        </ul>
                    </div>
                    <div className={classes.col}>
                        <h4 className={[classes.colTitle, 'font-17'].join(' ')}>
                            {t("conditions_68")}
                        </h4>
                        <ul className="font-17">
                            <li>
                                {t("conditions_69")}
                            </li>
                            <li>
                                {t("conditions_70")}
                            </li>
                            <li>
                                {t("conditions_71")}
                            </li>
                            <li>
                                {t("conditions_72")}
                            </li>
                            <li>
                                {t("conditions_73")}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhatOffer;
