import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/AccountsPage/Banner/Banner";
import AccountTypes from "../../components/AccountsPage/AccountTypes/AccountTypes";
import HomeOurPartners from "../../components/MainComponents/HomeOurPartners/HomeOurPartners";

const AccountsPage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Account Types</title>
        <meta name="title" content="Tailored Account Types | Avos Finance Offers Personalized Solutions for Traders" />
        <meta property="og:title" content="Tailored Account Types | Avos Finance Offers Personalized Solutions for Traders" />
        <meta property="twitter:title" content="Tailored Account Types | Avos Finance Offers Personalized Solutions for Traders" />

        <meta name="description" content="Avos Finance provides a range of account types designed to cater to traders of all levels. Whether you're a beginner or an experienced investor, find the perfect account type that suits your trading style." />
        <meta property="og:description" content="Avos Finance provides a range of account types designed to cater to traders of all levels. Whether you're a beginner or an experienced investor, find the perfect account type that suits your trading style." />
        <meta property="twitter:description" content="Avos Finance provides a range of account types designed to cater to traders of all levels. Whether you're a beginner or an experienced investor, find the perfect account type that suits your trading style." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <AccountTypes />
        <HomeOurPartners />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default AccountsPage;
