import React from "react";
import classes from './TradingHours.module.scss';
import {Trans, useTranslation} from "react-i18next";

const Table = () => {
    const { t } = useTranslation();
    const rows = [
        [
            'conditions_1',
            'conditions_2',
            'conditions_3',
        ],
        [
            'AUS200',
            'conditions_4',
            'conditions_5',
        ],
        [
            '',
            'conditions_6',
            '',
        ],
        [
            'conditions_7',
            'conditions_8',
            'conditions_9',
        ],
        [
            '',
            'conditions_10',
            '',
        ],
        [
            'N50',
            'conditions_11',
            'conditions_12',
        ],
        [
            '',
            'conditions_13',
            '',
        ],
        [
            'JP225',
            'conditions_14',
            'conditions_15',
        ],
        [
            '',
            'conditions_16',
            '',
        ],
        [
            'HK50',
            'conditions_17',
            'conditions_18',
        ],
        [
            '',
            'conditions_19',
            '',
        ],
    ];
    return (
        <table>
            <tbody>
                {rows.map((row, rowIndex) =>
                    <tr key={rowIndex}>
                        {row.map((col, colIndex) =>
                            <td key={colIndex}>
                                <Trans>
                                    {col}
                                </Trans>
                            </td>
                        )}
                    </tr>
                )}
            </tbody>
        </table>
    );
};

export default Table;