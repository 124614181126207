import React, { useEffect, useRef } from "react";
import classes from "./Banner.module.scss"
import Button from '../../GlobalComponents/Button/Button'
import img1 from "../../../assets/img/main/1/item1.png"
import img3 from "../../../assets/img/main/1/item3.png"
import img4 from "../../../assets/img/main/1/item4.png"
import img5 from "../../../assets/img/main/1/item5.png"
import imgM1 from "../../../assets/img/main/1/2.png"
import imgM2 from "../../../assets/img/main/1/1.png"
import imgMob from "../../../assets/img/main/1/mob.png"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const Banner = () => {
    const { t } = useTranslation();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);
    const ref5 = useRef(null);
    const ref6 = useRef(null);
    const refS1 = useRef(null);
    const refS2 = useRef(null);
    const refS3 = useRef(null);
    const refS4 = useRef(null);
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            gsap.fromTo(ref1.current, {
                y: 150,
                x: 0,
                scale: 0.8,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                scale: 1,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref2.current, {
                y: -220,
                x: -50,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref3.current, {
                y: -200,
                x: 120,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref4.current, {
                y: -120,
                x: 80,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref5.current, {
                y: -100,
                x: 20,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(ref6.current, {
                y: -100,
                x: 80,
                autoAlpha: 0,
            }, {
                y: 0,
                x: 0,
                autoAlpha: 1,
                delay: 0.3,
                duration: 1,
            });


            gsap.fromTo(refS2.current, {
                y: "-100%",
            }, {
                y: 0,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(refS3.current, {
                x: "-100%",
            }, {
                x: 0,
                delay: 0.3,
                duration: 1,
            });
            gsap.fromTo(refS4.current, {
                y: "100%",
            }, {
                y: 0,
                delay: 0.3,
                duration: 1,
            });

        }
    }, []);
    return (
        <div className={[classes.banner, "pb"].join(" ")}>
            <div className={[classes.bannerBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.bannerRow, ""].join(" ")}>
                        <div className={[classes.bannerImg, ""].join(" ")} ref={refS1}>
                            <img src={imgM2} alt="Trading" />
                        </div>

                        <div className={[classes.bannerRowLeft, ""].join(" ")}>
                            <div className={[classes.bannerRowLeftContent, ""].join(" ")}>
                                <p className={[classes.bannerRowLeftContentOverTitle, "font-50"].join(" ")}>
                                    <span ref={refS2}>{t("home_1")}</span>
                                </p>
                                <h1 className={[classes.bannerRowLeftContentTitle, "font-120"].join(" ")}>
                                    {t("home_2")}
                                </h1>
                                <p className={[classes.bannerRowLeftContentText, "font-30"].join(" ")}>
                                    <span ref={refS4}>
                                        {t("home_3")}
                                    </span>
                                </p>
                            </div>
                            <div className={[classes.bannerMob, ""].join(" ")}>
                                <img src={imgMob} alt="Trading" />
                            </div>
                            <div className={[classes.bannerRowLeftBtn, ""].join(" ")}>
                                <Button>{t("btn_start")}</Button>
                            </div>
                        </div>
                        <div className={[classes.bannerRowRight, ""].join(" ")}>
                            <div className={[classes.bannerRowRightImgs, ""].join(" ")}>
                                <div className={[classes.bannerRowRightImgsImg1, ""].join(" ")} ref={ref2}>
                                    <img src={img1} alt="Trading" />
                                </div>
                                <div className={[classes.bannerRowRightImgsImg3, ""].join(" ")} ref={ref4}>
                                    <img src={img3} alt="Trading" />
                                </div>
                                <div className={[classes.bannerRowRightImgsImg4, ""].join(" ")} ref={ref5}>
                                    <img src={img4} alt="Trading" />
                                </div>
                                <div className={[classes.bannerRowRightImgsImg5, ""].join(" ")} ref={ref6}>
                                    <img src={img5} alt="Trading" />
                                </div>
                                <div className={[classes.bannerRowRightImgsMain1Img, ""].join(" ")} >
                                    <img ref={ref1} src={imgM1} alt="Trading" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
