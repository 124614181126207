import HomePage from '../pages/HomePage/HomePage';
import ConditionsPage from '../pages/ConditionsPage/ConditionsPage';
import ProductsPage from '../pages/ProductsPage/ProductsPage';
import AccountsPage from '../pages/AccountsPage/AccountsPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AboutPage from '../pages/AboutPage/AboutPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/conditions', element: ConditionsPage },
    { path: '/products', element: ProductsPage },
    { path: '/accounts', element: AccountsPage },
    { path: '/faq', element: FaqPage },
    { path: '/about', element: AboutPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/conditions', element: ConditionsPage },
    { path: '/:lang/products', element: ProductsPage },
    { path: '/:lang/accounts', element: AccountsPage },
    { path: '/:lang/faq', element: FaqPage },
    { path: '/:lang/about', element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: 'conditions', text: 'nav_1' },
    { id: 2, path: 'products', text: 'nav_2' },
    { id: 3, path: 'accounts', text: 'nav_3' },
    { id: 4, path: 'faq', text: 'nav_4' },
    { id: 5, path: 'about', text: 'nav_5' },
];
