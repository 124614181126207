import React, { useEffect, useRef } from "react";
import classes from './WhyBecome.module.scss';
import { useTranslation, Trans } from "react-i18next";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});

const WhyBecome = () => {
    const { t } = useTranslation();
    const bodyRef = useRef(null);
    const cardsRef = useRef(null);
    const cardRef_01 = useRef(null);
    const cardRef_02 = useRef(null);
    const cardRef_03 = useRef(null);
    const cardRef_04 = useRef(null);
    const cards = [
        {
            ref: cardRef_01,
            title: 'conditions_49',
            text: 'conditions_50',
        },
        {
            ref: cardRef_02,
            title: 'conditions_51',
            text: 'conditions_52',
        },
        {
            ref: cardRef_03,
            title: 'conditions_53',
            text: 'conditions_54',
        },
        {
            ref: cardRef_04,
            title: 'conditions_55',
            text: 'conditions_56',
        },
    ];
    useEffect(() => {
        if (document.documentElement.clientWidth > 1220) {
            let ctx = gsap.context(() => {
                gsap.to(cardRef_01.current, {
                    x: 0,
                    rotate: 4,
                    ease: "Power1.easeInOut",
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "center center",
                        end: "+=600px",
                        scrub: 1,
                    }
                });
                gsap.to(cardRef_02.current, {
                    x: 0,
                    rotate: -8,
                    ease: "Power1.easeInOut",
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "center center",
                        end: "+=800px",
                        scrub: 1,
                    }
                });
                gsap.to(cardRef_03.current, {
                    x: 0,
                    rotate: 11,
                    ease: "Power1.easeInOut",
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "center center",
                        end: "+=1000px",
                        scrub: 1,
                    }
                });
                gsap.to(cardRef_04.current, {
                    x: 0,
                    rotate: -9,
                    ease: "Power1.easeInOut",
                    scrollTrigger: {
                        trigger: bodyRef.current,
                        start: "center center",
                        end: "+=1200px",
                        scrub: 1,
                        pin: bodyRef.current,
                    }
                });
            });

            let ctxClick_01 = gsap.context((self) => {
                self.add("onClick", (e) => {
                  gsap.to(cardRef_01.current, {
                    scale: 1.1,
                    zIndex: 10,
                    rotate: 0,
                    autoAlpha: 1,
                  });
                  gsap.to(cardRef_02.current, {
                    scale: 1,
                    zIndex: 2,
                    rotate: -8,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_03.current, {
                    scale: 1,
                    zIndex: 3,
                    rotate: 11,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_04.current, {
                    scale: 1,
                    zIndex: 4,
                    rotate: -9,
                    autoAlpha: 0.8,
                  });
                });
            }, cardRef_01.current);
            let ctxClick_02 = gsap.context((self) => {
                self.add("onClick", (e) => {
                  gsap.to(cardRef_01.current, {
                    scale: 1,
                    zIndex: 1,
                    rotate: 4,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_02.current, {
                    scale: 1.1,
                    zIndex: 10,
                    rotate: 0,
                    autoAlpha: 1,
                  });
                  gsap.to(cardRef_03.current, {
                    scale: 1,
                    zIndex: 3,
                    rotate: 11,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_04.current, {
                    scale: 1,
                    zIndex: 4,
                    rotate: -9,
                    autoAlpha: 0.8,
                  });
                });
            }, cardRef_02.current);
            let ctxClick_03 = gsap.context((self) => {
                self.add("onClick", (e) => {
                  gsap.to(cardRef_01.current, {
                    scale: 1,
                    zIndex: 1,
                    rotate: 4,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_02.current, {
                    scale: 1,
                    zIndex: 2,
                    rotate: -8,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_03.current, {
                    scale: 1.1,
                    zIndex: 10,
                    rotate: 0,
                    autoAlpha: 1,
                  });
                  gsap.to(cardRef_04.current, {
                    scale: 1,
                    zIndex: 4,
                    rotate: -9,
                    autoAlpha: 0.8,
                  });
                });
            }, cardRef_03.current);
            let ctxClick_04 = gsap.context((self) => {
                self.add("onClick", (e) => {
                  gsap.to(cardRef_01.current, {
                    scale: 1,
                    zIndex: 1,
                    rotate: 4,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_02.current, {
                    scale: 1,
                    zIndex: 2,
                    rotate: -8,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_03.current, {
                    scale: 1,
                    zIndex: 3,
                    rotate: 11,
                    autoAlpha: 0.8,
                  });
                  gsap.to(cardRef_04.current, {
                    scale: 1.1,
                    zIndex: 10,
                    rotate: 0,
                    autoAlpha: 1,
                  });
                });
            }, cardRef_04.current);
            cardRef_01.current.addEventListener("click", (e) => ctxClick_01.onClick(e));
            cardRef_02.current.addEventListener("click", (e) => ctxClick_02.onClick(e));
            cardRef_03.current.addEventListener("click", (e) => ctxClick_03.onClick(e));
            cardRef_04.current.addEventListener("click", (e) => ctxClick_04.onClick(e));

            return () => ctx.revert();
        }
    }, []);
    return (
        <section ref={bodyRef} className={classes.whyBecomeWrap}>
            <div className="pt pb container">
                <div className={classes.whyBecome}>
                    <h2 className={[classes.title, 'font-35'].join(' ')}>
                        {t("conditions_57")}
                    </h2>
                    <div className={classes.cardsWrap}>
                        <div ref={cardsRef} className={classes.cards}>
                            {cards.map((card, index) =>
                                <div ref={card.ref} key={index} className={classes.card}>
                                    <h4 className={[classes.cardTitle, 'font-17'].join(' ')}>
                                        <Trans>{card.title}</Trans>
                                    </h4>
                                    <p className={classes.cardText}>
                                        <Trans>{card.text}</Trans>
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyBecome;
