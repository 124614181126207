import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/MainComponents/Banner/Banner";
import Numbers from "../../components/MainComponents/Numbers/Numbers";
import Make from "../../components/MainComponents/Make/Make";
import Will from "../../components/MainComponents/Will/Will";
import Trading from "../../components/MainComponents/Trading/Trading";
import Our from "../../components/MainComponents/Our/Our";
import Trade from "../../components/MainComponents/Trade/Trade";
import Slider from "../../components/MainComponents/Slider/Slider";
import OurRewards from "../../components/MainComponents/OurRewards/OurRewards";
import HomeOurPartners from "../../components/MainComponents/HomeOurPartners/HomeOurPartners";

const HomePage = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Avos Finance</title>
        <meta name="title" content="Avos Finance | Your Premier Trading Platform for Financial Success" />
        <meta property="og:title" content="Avos Finance | Your Premier Trading Platform for Financial Success" />
        <meta property="twitter:title" content="Avos Finance | Your Premier Trading Platform for Financial Success" />

        <meta name="description" content="Experience the power of Avos Finance, a leading trading platform offering advanced tools, comprehensive market analysis, and a user-friendly interface. Join us today and take control of your financial future." />
        <meta property="og:description" content="Experience the power of Avos Finance, a leading trading platform offering advanced tools, comprehensive market analysis, and a user-friendly interface. Join us today and take control of your financial future." />
        <meta property="twitter:description" content="Experience the power of Avos Finance, a leading trading platform offering advanced tools, comprehensive market analysis, and a user-friendly interface. Join us today and take control of your financial future." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <Numbers />
        <Make />
        <Will />
        <Trading />
        <Our />
        <Trade />
        <Slider />
        <OurRewards />
        <HomeOurPartners />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
