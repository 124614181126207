import { useEffect, useState, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import classes from "./Numbers.module.scss"
import { useTranslation } from "react-i18next";
gsap.registerPlugin(ScrollTrigger);
gsap.config({
    force3D: true,
});
const Numbers = () => {
    const [width, setWidth] = useState();
    const { t } = useTranslation();
    const swiperWrapRef1 = useRef(null);


    const ref1 = useRef(null);
    const ref2 = useRef(null);


    useEffect(() => {
        if (width > 1220) {
            gsap.fromTo(ref1.current, {
                y: 600,
                x: 0,

                scrollTrigger: {
                    trigger: swiperWrapRef1.current,
                    end: "bottom bottom",
                    scrub: 2,
                },
            }, {
                y: 0,
                x: 0,

                delay: 0.3,
                duration: 1,
                scrollTrigger: {
                    trigger: swiperWrapRef1.current,
                    end: "bottom bottom",
                    scrub: 2,
                },
            });
            gsap.fromTo(ref2.current, {
                y: 600,
                x: 0,

                scrollTrigger: {
                    trigger: swiperWrapRef1.current,
                    end: "bottom bottom",
                    scrub: 2,
                },
            }, {
                y: 0,
                x: 0,

                delay: 0.3,
                duration: 1,
                scrollTrigger: {
                    trigger: swiperWrapRef1.current,
                    end: "bottom bottom",
                    scrub: 2,
                },
            });


        }
    }, [width]);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", (event) => {
            setWidth(window.innerWidth);
        });
    }, []);
    return (
        <div className={[classes.numbers, ""].join(" ")}
            ref={swiperWrapRef1} >
            <div className={[classes.numbersBody, ""].join(" ")}>
                <div className="container">
                    <div className={[classes.numbersRow, ""].join(" ")}>
                        <div className={[classes.numbersRowColumn, ""].join(" ")}>
                            <div className={[classes.numbersRowColumnMobWrap, ""].join(" ")}>
                                <div className={[classes.numbersRowColumnNumber, "font-40"].join(" ")}>
                                    1
                                </div>
                                <div className={[classes.numbersRowColumnMobWrapRight, ""].join(" ")}>
                                    <div className={[classes.numbersRowColumnTitle, "font-18"].join(" ")}>
                                        {t("home_4")} <span></span>
                                        {t("home_5")}
                                    </div>
                                    <div className={[classes.numbersRowColumnText, "font-17"].join(" ")}>
                                        {t("home_6")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.numbersRowColumn, ""].join(" ")}
                            ref={ref1}
                        >
                            <div className={[classes.numbersRowColumnMobWrap, ""].join(" ")}>
                                <div className={[classes.numbersRowColumnNumber, "font-40"].join(" ")}>
                                    2
                                </div>
                                <div className={[classes.numbersRowColumnMobWrapRight, ""].join(" ")}>
                                    <div className={[classes.numbersRowColumnTitle, "font-18"].join(" ")}>
                                        {t("home_7")}
                                        <span></span>
                                        {t("home_8")}
                                    </div>
                                    <div className={[classes.numbersRowColumnText, "font-17"].join(" ")}>
                                        {t("home_9")} <span></span> {t("home_10")}<span></span> {t("home_11")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.numbersRowColumn, ""].join(" ")}>
                            <div className={[classes.numbersRowColumnMobWrap, ""].join(" ")}>
                                <div className={[classes.numbersRowColumnNumber, "font-40"].join(" ")}>
                                    3
                                </div>
                                <div className={[classes.numbersRowColumnMobWrapRight, ""].join(" ")}>
                                    <div className={[classes.numbersRowColumnTitle, "font-18"].join(" ")}>
                                        {t("home_12")}
                                        <span></span>
                                        {t("home_13")}
                                    </div>
                                    <div className={[classes.numbersRowColumnText, "font-17"].join(" ")}>
                                        {t("home_14")} <span></span> {t("home_15")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.numbersRowColumn, ""].join(" ")}
                            ref={ref2}
                        >
                            <div className={[classes.numbersRowColumnMobWrap, ""].join(" ")}>
                                <div className={[classes.numbersRowColumnNumber, "font-40"].join(" ")}>
                                    4
                                </div>
                                <div className={[classes.numbersRowColumnMobWrapRight, ""].join(" ")}>
                                    <div className={[classes.numbersRowColumnTitle, "font-18"].join(" ")}>
                                        {t("home_16")}
                                        <span></span>
                                        {t("home_17")}
                                    </div>
                                    <div className={[classes.numbersRowColumnText, "font-17"].join(" ")}>
                                        {t("home_18")} <span></span> {t("home_19")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={[classes.numbersRowColumn, ""].join(" ")}>
                            <div className={[classes.numbersRowColumnMobWrap, ""].join(" ")}>
                                <div className={[classes.numbersRowColumnNumber, "font-40"].join(" ")}>
                                    5
                                </div>
                                <div className={[classes.numbersRowColumnMobWrapRight, ""].join(" ")}>
                                    <div className={[classes.numbersRowColumnTitle, "font-18"].join(" ")}>
                                        {t("home_20")} <span></span>
                                        {t("home_21")}
                                    </div>
                                    <div className={[classes.numbersRowColumnText, "font-17"].join(" ")}>
                                        {t("home_22")} <span></span> {t("home_23")}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </div>

    )
}

export default Numbers